import { Injectable, inject } from '@angular/core';
import { PermissionsService, Roles } from '@mca/auth/api';
import { UserName, UserService } from '@mca/user/api';
import { map, take, tap } from 'rxjs';
import { CommUserSet, McaOfferData, OfferTemplateCommissions } from '../../entities/offer';
import { McaCommisionUser, PayStrategy } from '../../entities/commisionuser';
import { RxState } from '@rx-angular/state';
import { McaCommisionUserDisp } from '../../entities/mcarec';

interface State {
  userNameMap: Record<number, UserName>;
}

@Injectable({
  providedIn: 'root',
})
export class IsoUserMapService extends RxState<State> {
  private permissionsService = inject(PermissionsService);
  private userService = inject(UserService);

  get isoRoleId() {
    return this.permissionsService.getRoleId(Roles.role_iso);
  }
  get isoRelationId() {
    return this.permissionsService.getRoleId(Roles.role_iso_relations);
  }
  get commissionRoleId() {
    return this.permissionsService.getRoleId(Roles.role_commission);
  }
  get insuranceRoleId() {
    return this.permissionsService.getRoleId(Roles.role_ins_commission);
  }
  get cfRoleId() {
    return this.permissionsService.getRoleId(Roles.role_contract_fee);
  }
  get commissionUserRoles() {
    return [this.isoRoleId, this.isoRelationId, this.insuranceRoleId, this.commissionRoleId, this.cfRoleId];
  }

  constructor() {
    super();
    this.connect('userNameMap', this.userService.getCachedMap());
  }

  isCfUser(record: McaCommisionUserDisp) {
    return this.get('userNameMap')[record.userid]?.roles.some(roleId => roleId === this.cfRoleId);
  }

  isOfferIsoCommisionEqualToSet(userSet: CommUserSet, offerData: McaOfferData) {
    const userSetCommissions = this.filterUsersByRoles(userSet.users, [this.isoRoleId, this.isoRelationId]).reduce(
      (acc, user) => acc + +(user?.commisionpct ?? 0),
      0,
    );
    const offerCommisions = this.filterUsersByRoles(offerData.commissions, [this.isoRoleId, this.isoRelationId]).reduce(
      (acc, user) => acc + +(user?.percent ?? 0),
      0,
    );
    return userSetCommissions === offerCommisions;
  }

  getOfferCommissions(userSet: CommUserSet) {
    return this.getMappedRoleUsers(this.commissionUserRoles, userSet.users).pipe(
      map(([isoUser, isoRelUser, isoInsUser, commissionUser, cfUser]) => ({
        iso_commission_rtr_pct: isoUser?.commisionpct ?? 0,
        iso_contract_fee_pct: isoUser?.contract_fee_allocation ?? 0,
        isorep_commission_pct: isoRelUser?.commisionpct ?? 0,
        isorep_contract_fee_pct: isoRelUser?.contract_fee_allocation ?? 0,
        ins_commission_rtr: isoInsUser?.commisionpct ?? 0,
        ins_commission_contract_fee_pct: isoInsUser?.contract_fee_allocation ?? 0,
        commission_comm_rtr_pct: commissionUser?.commisionpct ?? 0,
        commission_contract_fee_pct: commissionUser?.contract_fee_allocation ?? 0,
        cf_commission_rtr_pct: cfUser?.commisionpct ?? 0,
        cf_contract_fee_pct: cfUser?.contract_fee_allocation ?? 0,
      })),
    );
  }

  setOfferTemplateCommissionsOnUserSet$(userSet: CommUserSet, commsissions: OfferTemplateCommissions) {
    return this.getMappedRoleUsers(this.commissionUserRoles, userSet.users).pipe(
      tap(([isoUser, isoRelUser, isoInsUser, commissionUser, cfUser]) => {
        if (isoUser) {
          isoUser.commisionpct = commsissions.iso_commission_rtr_pct;
          isoUser.contract_fee_allocation = commsissions.iso_contract_fee_pct;
        }
        if (isoRelUser) {
          isoRelUser.commisionpct = commsissions.isorep_commission_pct;
          isoRelUser.contract_fee_allocation = commsissions.isorep_contract_fee_pct;
        }
        if (cfUser) {
          cfUser.commisionpct = commsissions.cf_commission_rtr_pct;
          cfUser.contract_fee_allocation = commsissions.cf_contract_fee_pct;
        }
        if (isoInsUser) {
          isoInsUser.commisionpct = commsissions.ins_commission_rtr;
          isoInsUser.contract_fee_allocation = commsissions.ins_commission_contract_fee_pct;
        }
        if (commissionUser) {
          commissionUser.commisionpct = commsissions.commission_comm_rtr_pct;
          commissionUser.contract_fee_allocation = commsissions.commission_contract_fee_pct;
        }
      }),
      map(users => users.filter(u => !!u)),
    );
  }

  // find and print single pair: iso commission / iso relation commission
  getCommissionString(userSet: CommUserSet, data: McaOfferData) {
    return this.getMappedRoleUsers([this.isoRoleId, this.isoRelationId], userSet.users).pipe(
      map(users => users.map(user => data.commissions.find(comm => comm.userid === user?.userid))),
      map(([isoCommission, isoRelationCommission]) => {
        const isoValue = isoCommission ? +isoCommission.percent + +isoCommission.contract_fee + '%' : '-';
        const isoRelationValue = isoRelationCommission ? +isoRelationCommission.percent + +isoRelationCommission.contract_fee + '%' : '-';
        return isoValue + ' / ' + isoRelationValue;
      }),
    );
  }

  getUserSetCommissionsFromOfferCommissions$(commissions: OfferTemplateCommissions, commUsers: McaCommisionUserDisp[]) {
    const userSet = new CommUserSet(1, 1, commUsers);
    return this.setOfferTemplateCommissionsOnUserSet$(userSet, commissions);
  }

  verifyCommissionStrategies(userSet: CommUserSet) {
    return this.getMappedRoleUsers(
      [this.isoRoleId, this.isoRelationId, this.insuranceRoleId, this.commissionRoleId, this.cfRoleId],
      userSet.users,
    ).pipe(
      map(([isoUser, isoRelUser, isoInsUser, commissionUser, cfUser]) => {
        const messages = [];
        if (isoInsUser?.paystrategy !== PayStrategy.increment) {
          messages.push('Insurance commission strategy should be increment');
        }
        if (cfUser?.paystrategy !== PayStrategy.firstAll) {
          messages.push('Contract Fees commission strategy should be First Increment (All)');
        }
        if (commissionUser?.paystrategy !== PayStrategy.increment) {
          messages.push('Commission strategy should be increment');
        }
        if (isoRelUser?.paystrategy !== PayStrategy.increment) {
          messages.push('ISO relations strategy should be increment');
        }
        if (isoUser?.paystrategy === PayStrategy.firstAll) {
          messages.push('ISO strategy cannot be First Increment (All)');
        }
        return messages;
      }),
    );
  }

  private getMappedRoleUsers<
    T extends {
      userid: number;
    } = McaCommisionUser,
  >(roles: number[], setUsers: T[]) {
    const users = [...setUsers];
    return this.userService.getCachedMap().pipe(
      take(1),
      map(userMap =>
        roles.map(role => {
          const userIndex = users.findIndex(u => userMap[u.userid]?.roles.some(roleId => roleId === role));
          return users.splice(userIndex, 1)[0];
        }),
      ),
    );
  }

  private filterUsersByRoles<
    T extends {
      userid: number;
    } = McaCommisionUser,
  >(users: T[], roles: number[]) {
    const userMap = this.userService.get('nameMap');
    return users.filter(u => roles.map(role => userMap[u.userid]?.roles.some(roleId => roleId === role)));
  }
}
