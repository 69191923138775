/* eslint-disable @typescript-eslint/naming-convention */
import {
  BankStatementRec,
  BankStatementShit,
  DBARec,
  initBankStatementRec,
  initOutstandingLoan,
  NoteRec,
  OutstandingLoan,
  Owner,
  PaymentFrequency,
  TradeReferenceRec,
  McaRateTypes,
  MongoAddress,
} from '@mca/shared/domain';
import { getNumber } from '@mca/shared/util';
import { Bankrec, Person, UserName } from '@mca/user/api';
import { McaCommisionUser } from './commisionuser';
import { CredCardStatementRec, CredCardStatementShit } from './credcard-statement-shit';
import { InvolvedUser } from './involved-user';
import { McaLender } from './mcalender';
import { RenewalRec } from './renewalrec';
import { McaOfferData } from './offer';

export interface McaRate {
  current: boolean;
  expiry?: string;
  id: number;
  mcaid: number;
  rate: number;
  rate_type: McaRateTypes;
}

export class McaLenderDisplay extends McaLender {
  userid = 0;
  dispName = '';
}

export class McaCommisionUserDisp extends McaCommisionUser {
  dispName = '';
  totalComm = 0;
  cfAmount = 0;
  roles: number[] = [];
}

export interface McaDoc {
  id: number;
  create_timestamp: string;
  create_userid: number;
  description: string;
  filename: string;
  mcaid: number;
  visibility: string;
  categoryid?: number;
  wasEdited?: boolean;
}

export interface MCAPosition {
  fee_pct: number;
  fixed_cf: number;
  funding_amount: number;
  mcaid: number;
  payment_amount: number;
  payment_freq: PaymentFrequency | 0;
  switch_to_daily_payments: boolean;
  payment_status: number;
  program: number;
  status: number;
  status_reason_id: number;
  create_timestamp: string;
  rates: McaRate[];
  admin_fee?: number;
  nsf_fee?: number;
}

export class MCARec {
  id = 0;
  parentId = 0;

  //  Summary section
  paybackAmount = 0;
  totalConsPaidBack = 0;
  lenderOneTimeMgmtFees = 0;
  lenderDailyMgmtFees = 0;
  lenderOneTimeAdminFees = 0;
  numRecievedPayments = 0;

  Avg_Daily_Bank_Balance_$ = 0;

  mcaApplicationReceived: Date = new Date();
  mcaApplicationApprovalDate?: Date;
  depStartDate?: Date;
  withdFreq = 0;
  depFreq = 0;

  iso = 0;
  isoRelation = 0;
  dbaRec: DBARec = new DBARec();

  eff_fund_amt = 0;
  fundingAmountRequested = 0;
  dateFunded = new Date(0);
  adv_balance = 0;
  cons_balance = 0;
  discount_cons_balance = 0;
  active_rate_balance = 0;
  netAmtToMerchant = 0;
  factorRate = 1;
  discountFactorRate = 0;
  payOnDays = '';
  num_payments = 0;
  achWithdrawalAmt = 0;
  outstandingCashWith = '';
  outstandingCashBalance = 0;
  mailingAddressSameAsDBA = true;
  mailingAddress = new MongoAddress();
  taxIdNumber = '';
  businessType = '';
  haveCashAdvance = false;
  currentCreditCardProcessor = '';
  dateStartedAcceptingCreditCards?: Date;
  useOfProceeds = false;
  SICMCC = '';
  grossAnnualSales = 0;
  averageMonthlyCreditCardVol = 0;
  monthVolume1 = 0;
  monthTrans1 = 0;
  monthVolume2 = 0;
  monthTrans2 = 0;
  monthVolume3 = 0;
  monthTrans3 = 0;
  monthVolume4 = 0;
  monthTrans4 = 0;

  // DBA
  tradeReferenceRec: TradeReferenceRec[] = [];

  // Finance
  bankaccount: Bankrec[] = [];

  bankStatementShits: BankStatementShit[] = [];
  bankStatementTotal: BankStatementRec = new BankStatementRec();
  bankStatementAvg: BankStatementRec = new BankStatementRec();
  credCardStatementShit: CredCardStatementShit[] = [];
  credCardStatementTotal: CredCardStatementRec = new CredCardStatementRec();
  credCardStatementAvg: CredCardStatementRec = new CredCardStatementRec();

  owners: Owner[] = [];
  accounts: any[] = [];
  finHistory: any[] = [];

  achBillingFreq = '';
  // day of month
  achBillingDay = 0;
  // weekly
  achCombinedPayFreq = '';
  achCombinedPayDay = '';

  totalProfitAmount = 0;
  totalLenderProfitAmount = 0;
  totalCommPct = 0;
  dailyResidualPct = 0;
  deductCompanyCommFrom = '';

  projectedDailyPaybackAmt = 0;
  actualDailyPaybackAmt = 0;
  paybackPerf = 0;
  paybackStartDate: Date = new Date();
  paybackExpectedEndDate: Date = new Date();

  cidmid = '';
  globalFinancialAcct = '';
  fdrMessagewayAcct = '';
  kenneyBankTrustAcct = '';
  nationalMerchantCenterAcct = '';
  integrityPaymentAcct = '';
  fortisPaymentAcct = '';
  powerPayPaymentAcct = '';
  benchmarkAcct = '';
  evoAcct = '';
  getiPaymentsAcct = '';
  newtekPaymentsAcct = '';
  miCampAcct = '';

  ownerName = ''; // hack for list

  // not part of object...
  involvedUsers: InvolvedUser[] = [];
  renewalRecs: RenewalRec[] = [];
  qaHolder: any = {};
  noteRecs: NoteRec[] | null = null;
  investors: McaLenderDisplay[] = [];
  mcaCommisionUsers: McaCommisionUserDisp[] = [];
  outstandingLoans: OutstandingLoan[] = [];

  _mcaDocs: any[] | null = null;

  _isoPct = 0;

  useACHForDeposits = false;
  useACHForPayments = true;
  useDiscountRate = true;

  status_change_timestamp = '';
  revenue = 0;

  exposure_profit = 0;
  recalls = 0;
  pendingFees = 0;
  contract_fee = 0;
  sent_commissions = 0;
  nonachFeeUnpaid = 0;
  discount = 0;
  transVenue = '';
  mappedVenue?: number;

  position: Partial<MCAPosition> = {
    fee_pct: 0,
    fixed_cf: 0,
    funding_amount: 0,
    program: 0,
    mcaid: 0,
    payment_amount: 0,
    payment_freq: 0,
    payment_status: 1,
    status: 0,
    status_reason_id: 0,
    create_timestamp: '',
    rates: [],
  };

  static getRateValue(mca: MCARec, type: McaRateTypes) {
    const rate = mca.position.rates?.find(v => v.rate_type === type);
    return rate?.rate || 0;
  }

  toCreatePayload() {
    const data = { ...this } as any;
    delete data.id;
    delete data.position;
    return data;
  }
}

export class MCADocsContractParams {
  depPct = 0;
  averageRev = 0;
  transfers: Array<{ amount: number; prevDate: Date }> = [];
  earlyPayment: { amount: number; payDate: Date } = { amount: 0, payDate: new Date() };
  first_depostit = '';
}

// tp: a - ammount, w - number of payments
export const mcaRecalcFunding = (mca: MCARec, tp: 'a' | 'w', calcRate = undefined) => {
  mca.paybackAmount = Number(mca.paybackAmount);
  const rate = calcRate === undefined ? Number(mca.factorRate) : calcRate;
  mca.position.funding_amount = Number(mca.position.funding_amount);

  if (tp === 'w') {
    mca.position.payment_amount = mca.paybackAmount / Number(mca.num_payments);
  } else {
    mca.num_payments = Math.ceil(mca.paybackAmount / Number(mca.position.payment_amount));
  }

  mca.paybackAmount = mca.position.funding_amount * rate;
  mca.totalProfitAmount = mca.paybackAmount - mca.position.funding_amount;

  mca.totalCommPct = Number(mca.totalCommPct);
  mca.totalCommPct = Number(mca.totalCommPct);
  if (!mca.totalCommPct) {
    mca.totalCommPct = Number(0);
  }
  let comm = Number(mca.totalCommPct);
  if (mca.deductCompanyCommFrom === 'Funding Amount') {
    comm = (mca.position.funding_amount * comm) / 100;
  } else if (mca.deductCompanyCommFrom === 'Payback Amount') {
    comm = (mca.paybackAmount * comm) / 100;
  } else if (mca.deductCompanyCommFrom === 'Total Profit') {
    comm = (mca.totalProfitAmount * comm) / 100;
  }

  mca.totalLenderProfitAmount = mca.totalProfitAmount - comm;
};

export const mcaLoad = (mca: MCARec, loadedOffer?: McaOfferData) => {
  if (!mca?.tradeReferenceRec) {
    mca.tradeReferenceRec = [];
  }
  mca.paybackAmount = getNumber(mca.position.funding_amount, 0) * getNumber(mca.factorRate, 0);
  mca.totalConsPaidBack = getNumber(mca.totalConsPaidBack, 0);
  mca.factorRate = loadedOffer?.rates[0].rate || getNumber(mca.factorRate, 1);
  mca.position.funding_amount = loadedOffer?.amount || getNumber(mca.position.funding_amount, 0);
  mca.num_payments = getNumber(mca.num_payments, 0);
  mca.adv_balance = getNumber(mca.adv_balance, 0);
  mca.cons_balance = getNumber(mca.cons_balance, 0);
  mca.netAmtToMerchant = getNumber(mca.netAmtToMerchant, 0);
  mca.averageMonthlyCreditCardVol = getNumber(mca.averageMonthlyCreditCardVol, 0);
  mca.Avg_Daily_Bank_Balance_$ = getNumber(mca.Avg_Daily_Bank_Balance_$, 0);
  mca.lenderOneTimeAdminFees = getNumber(mca.lenderOneTimeAdminFees, 0);
  mca.depFreq = getNumber(mca.depFreq, PaymentFrequency.weekly);
  if (mca.depFreq === 0) {
    mca.depFreq = PaymentFrequency.weekly;
  }

  if (!mca.dateFunded) {
    mca.dateFunded = new Date(0);
  } else {
    mca.dateFunded = new Date(mca.dateFunded);
  }

  mca.mcaApplicationReceived = new Date(mca.mcaApplicationReceived);
  if (mca.dateStartedAcceptingCreditCards) {
    mca.dateStartedAcceptingCreditCards = new Date(mca.dateStartedAcceptingCreditCards);
  }

  mca.depStartDate = mca.depStartDate ? new Date(mca.depStartDate) : new Date();
  mca.paybackStartDate = mca.paybackStartDate ? new Date(mca.paybackStartDate) : new Date();
  if (mca.paybackExpectedEndDate) {
    mca.paybackExpectedEndDate = new Date(mca.paybackExpectedEndDate);
  }

  if (!mca.owners) {
    mca.owners = [];
  }
  if (!mca.owners.length) {
    mca.owners.push(new Owner());
  } else {
    for (const o of mca.owners) {
      o.ownershipStartDate = o.ownershipStartDate ? new Date(o.ownershipStartDate) : null;
      if (o.person.dob) {
        o.person.dob = new Date(o.person.dob);
      }
      if (!o.person.address) {
        o.person.address = new MongoAddress();
      }
    }
  }
  if (!mca.tradeReferenceRec.length) {
    mca.tradeReferenceRec.push({} as TradeReferenceRec);
  }

  if (!mca.accounts) {
    mca.accounts = [];
  }
  if (!mca.finHistory) {
    mca.finHistory = [];
  }

  if (!mca.dbaRec) {
    mca.dbaRec = new DBARec();
  }
  if (mca.dbaRec.businessStartDate) {
    mca.dbaRec.businessStartDate = new Date(mca.dbaRec.businessStartDate);
  }
  if (!mca.dbaRec.address) {
    mca.dbaRec.address = new MongoAddress();
  }

  if (!mca.bankaccount) {
    mca.bankaccount = [];
  }

  if (!mca.bankStatementShits) {
    mca.bankStatementShits = [];
  } else {
    mca.bankStatementShits.forEach(item => initBankStatementRec(item as any));
    mca.bankStatementShits.sort((a, b) => {
      if (a.year === b.year) {
        return a.name < b.name ? -1 : 1;
      } else {
        return a.year - b.year;
      }
    });
  }

  if (!mca.bankStatementTotal) {
    mca.bankStatementTotal = new BankStatementRec();
  }
  if (!mca.bankStatementAvg) {
    mca.bankStatementAvg = new BankStatementRec();
  }

  if (!mca.credCardStatementShit) {
    mca.credCardStatementShit = [];
  }

  if (!mca.credCardStatementTotal) {
    mca.credCardStatementTotal = new CredCardStatementRec();
  }
  if (!mca.credCardStatementAvg) {
    mca.credCardStatementAvg = new CredCardStatementRec();
  }

  if (!mca.achBillingFreq) {
    mca.achBillingFreq = 'Daily';
  }

  if (!mca.mailingAddress) {
    mca.mailingAddress = new MongoAddress();
  }
  // if(!mca.mailingAddressSameAsDBA)
  //     mca.mailingAddressSameAsDBA = true;

  // External refs section
  if (!mca.involvedUsers) {
    mca.involvedUsers = [];
  }
  if (!mca.renewalRecs) {
    mca.renewalRecs = [];
  }
  if (!mca.noteRecs) {
    mca.noteRecs = null;
  }
  if (!mca.investors) {
    mca.investors = [];
  }
  if (!mca.mcaCommisionUsers) {
    mca.mcaCommisionUsers = [];
  }

  if (!mca.outstandingLoans) {
    mca.outstandingLoans = [];
  } else {
    mca.outstandingLoans.forEach(item => initOutstandingLoan(item));
  }

  mca.outstandingLoans.forEach(i => {
    i.date = new Date(i.date);
    i.lasdtPayDate = new Date(i.lasdtPayDate);
  });
  mca.qaHolder = null;
  mca._isoPct = Number(0);
  if (!mca.revenue) {
    mca.revenue = 0;
  }

  if (!mca.useACHForDeposits) {
    mca.useACHForDeposits = false;
  }
  if (!mca.useACHForPayments) {
    mca.useACHForPayments = true;
  }
};

export const mcaLoadCommUsers = (mca: MCARec, comUsers: McaCommisionUser[], users: UserName[]) => {
  mca.mcaCommisionUsers = (comUsers || []).map(commUser => {
    const user = users.find(u => u.id === commUser.userid);
    const userName = user ? user.name : '';
    const roles = user?.roles ?? [];
    return commUserRecToDisp(commUser, userName, roles);
  });
};

export const lenderDispToDb = (lender: McaLenderDisplay) => ({
  id: lender.id,
  status: lender.status,
  userid: lender.userid || lender.user.id,
  part_pct: Number(lender.part_pct),
  upfront_fee_pct: Number(lender.upfront_fee_pct),
  mgmt_fee_base: lender.mgmt_fee_base,
  bypayment_fee_pct: Number(lender.bypayment_fee_pct),
  visible_docs: lender.visible_docs,
});

export const lenderRecToDisp = (rec: McaLender, dispName: string, user: Person): McaLenderDisplay => ({
  dispName,
  id: rec['id'],
  status: rec['status'],
  partAmt: rec['partAmt'],
  part_pct: rec['part_pct'],
  rtr: rec['rtr'],
  commisionamt: rec['commisionamt'],
  commisionpct: rec['commisionpct'],

  upfrontDefManagementFeeAmt: rec['upfrontDefManagementFeeAmt'],
  upfront_fee_pct: rec['upfront_fee_pct'],
  mgmt_fee_base: rec['mgmt_fee_base'],
  byPaymnetDefManagementFeeAmt: rec['byPaymnetDefManagementFeeAmt'],
  bypayment_fee_pct: rec['bypayment_fee_pct'],

  totalAmt: rec['totalAmt'],
  dailyAmt: rec['dailyAmt'],

  dailyMgmtFeePct: rec['dailyMgmtFeePct'],
  dailyMgmtFeeAmt: rec['dailyMgmtFeeAmt'],
  oneTimeMgmtFeePct: rec['oneTimeMgmtFeePct'],
  oneTimeMgmtFeeAmt: rec['oneTimeMgmtFeeAmt'],

  oneTimeAdminFeePct: rec['oneTimeAdminFeePct'],
  oneTimeAdminFeeAmt: rec['oneTimeAdminFeeAmt'],

  payments: rec['payments'],
  remPayments: rec['remPayments'],
  profit: rec['profit'],

  user,
  userid: rec.user?.id,
  visible_docs: rec['visible_docs'],
});

export const commUserDispToDb = (user: McaCommisionUserDisp): McaCommisionUser => ({
  id: Number(user.id),
  userid: user.userid,
  baseindicator: user.baseindicator,
  commisionamt: Number(user.commisionamt),
  commisionpct: Number(user.commisionpct),
  paystrategy: Number(user.paystrategy),
  setindex: Number(user.setindex),
  active: Boolean(user.active),
  dealvenue: Number(user.dealvenue),
  contract_fee_allocation: user.contract_fee_allocation,
  cfcomm_on_last_incr: user.cfcomm_on_last_incr,
  created_at: user.created_at,
});

export const commUserRecToDisp = (rec: McaCommisionUser, dispName: string, roles: number[]): McaCommisionUserDisp => ({
  dispName,
  id: rec.id,
  paystrategy: rec.paystrategy || 1,
  baseindicator: rec.baseindicator,
  commisionamt: Number(rec.commisionamt),
  commisionpct: Number(rec.commisionpct),
  userid: rec.userid,
  setindex: rec.setindex,
  active: rec.active,
  dealvenue: rec.dealvenue,
  contract_fee_allocation: rec.contract_fee_allocation,
  cfcomm_on_last_incr: rec.cfcomm_on_last_incr,
  totalComm: 0,
  cfAmount: 0,
  created_at: rec.created_at,
  roles,
});

export const getMcaActiveCommissions = (mca: MCARec) => {
  let comPct = 0;
  let comAmt = 0;
  mca.mcaCommisionUsers.forEach(comUser => {
    if (comUser.active) {
      comPct += comUser.commisionpct;
      comAmt += comUser.commisionamt;
    }
  });
  return [comPct, comAmt];
};

export const getLoadedOfferCommissions = (offer: McaOfferData) => {
  let comPct = 0;
  offer.commissions.forEach(comUser => {
    comPct += comUser.percent;
  });
  return [comPct];
};

// returns
// 0 - no active commissions
// 1 - All good
// -1 - inconsistent data
export const checkActiveCommissions = (mcaCommisionUsers: McaCommisionUser[]): number => {
  const node = mcaCommisionUsers.find(comm => comm.active);
  if (!node) {
    return 0;
  }
  for (const comm of mcaCommisionUsers) {
    if ((comm.active && comm.setindex !== node.setindex) || (comm.setindex === node.setindex && !comm.active)) {
      return -1;
    }
  }
  return 1;
};

export const getMcaVenue = (mca: MCARec, defVenue?: number) => mca.mcaCommisionUsers.find(u => u.active)?.dealvenue ?? defVenue;
